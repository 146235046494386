<template>
  <div class="animated fadeIn">
    <v-col cols="12">
          <v-tabs
          fixed-tabs
            class=""
            background-color="transparent"
            v-model="tab"
            
          >
            <v-tab class="tab-title"> پزشک </v-tab>
            <v-tab class="tab-title"> پرستار و پیراپزشک </v-tab>
          </v-tabs>
        <v-card class="br-card tab-item">
          <v-tabs-items v-model="tab">
            <!-- پزشک -->
            <v-tab-item >
              <person-tables 
              :get="'/clinic/hr/doctors'" 
              :job="'doctor'" 
              :faJob="'پزشک'"
              :Fields="Fields"
              :excelFields="excelFields"
              :new="'newDoctor/'" 
              :edit="'editDoctor/'" 
              :title="'پزشکان'"/>              
            </v-tab-item>
            <!-- پرستار و پیراپزشک -->
            <v-tab-item>
              <person-tables 
              :get="'/clinic/hr/nurses'" 
              :job="'nurse'" 
              :faJob="'پرستار'"
              :Fields="nurseFields"
              :excelFields="excelNurseFields"
              :new="'newNurse/'" 
              :edit="'editNurse/'" 
              :title="'پرستاران'"/>               
            </v-tab-item>
          </v-tabs-items>
        </v-card>
    </v-col>
  </div>
</template>
<script>
const PersonTables = () => import("@/components/hr/PersonTables");

export default {
  components: {
    PersonTables
  },
  data() {
    return {
      tab:null,
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "expertise", label: "تخصص" },
        { key: "docCode", label: "شماره نظام پزشکی" },
        { key: "status", label: "وضعیت " },
        { key: "operation", label: "مدیریت " },
      ],
      nurseFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "docCode", label: "شماره نظام" },
        { key: "status", label: "وضعیت " },
        { key: "operation", label: "مدیریت " },
      ],
      excelFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "expertise", label: "تخصص" },
        { key: "docCode", label: "شماره نظام پزشکی" },
        { key: "status", label: "وضعیت " }
      ],
      excelNurseFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "docCode", label: "شماره نظام" },
        { key: "status", label: "وضعیت " }
      ],
    };
  },
};
</script>

